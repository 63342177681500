@use '@angular/material' as mat;
@use './theme/theme' as theme;
@use './theme/typography' as typography;
@use './theme/partials/navigation.component-theme' as navigation;
@use './theme/partials/admin-page-theme' as admin-page;
@use './theme/partials/user-permission.component-theme' as user-permission;
@use './theme/partials/colors' as colors;
@import './partials/layout';
@import './partials/admin-page';
@import './partials/table-avatar';

@include mat.core();
@include mat.all-component-typographies(typography.$custom-typography);

@include mat.all-component-themes(theme.$admin-app-theme);

// set density
@include mat.button-density(0);

@include navigation.theme(theme.$admin-app-theme);
@include admin-page.theme(theme.$admin-app-theme);
@include user-permission.theme(theme.$admin-app-theme);

:root {
  @include colors.theme(theme.$admin-app-theme);
}

.dark {
  @include mat.all-component-colors(theme.$admin-app-dark-theme);
  @include navigation.theme(theme.$admin-app-dark-theme);
  @include admin-page.theme(theme.$admin-app-dark-theme);
  @include user-permission.theme(theme.$admin-app-dark-theme);
  @include colors.theme(theme.$admin-app-dark-theme);
}

.develop {
  @include mat.all-component-colors(theme.$admin-app-dev-theme);
  @include navigation.theme(theme.$admin-app-dev-theme);
  @include admin-page.theme(theme.$admin-app-dev-theme);
  @include user-permission.theme(theme.$admin-app-dev-theme);
  @include colors.theme(theme.$admin-app-dev-theme);
}

.test {
  @include mat.all-component-colors(theme.$admin-app-test-theme);
  @include navigation.theme(theme.$admin-app-test-theme);
  @include admin-page.theme(theme.$admin-app-test-theme);
  @include user-permission.theme(theme.$admin-app-test-theme);
  @include colors.theme(theme.$admin-app-test-theme);
}

/** {
  text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -webkit-text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}*/

html, body {
  height: 100%;
}

.mat-header-cell {
  font-size: 14px !important;
}

body {
  margin: 0;
}

.autosize .mdc-tooltip__surface {
  max-width: unset !important;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, .12);
  border-radius: 12px;
}

.tag[data-value="Prossima Apertura"] {
  background-color: #FFB700 !important;
}

.tag[data-value="Aperto"] {
  background-color: #36AEB5 !important;
}

.tag[data-value="Chiuso"] {
  background-color: #FF0000 !important;
}

.tag[data-value="In Scadenza"] {
  background-color: #FF5500 !important;
}

.tag[data-value="Da valutare"] {
  background-color: #F8976E !important;
}

.tag[data-value="Valutato"] {
  background-color: #6EBAF8 !important;
}

.tag[data-value="Environment"] {
  background-color: #44AB89 !important;
}

.tag[data-value="Social"] {
  background-color: #8780F1 !important;
}

.tag[data-value="Governance"] {
  background-color: #E85E67 !important;
}

.tag[data-value="Settoriale"] {
  background-color: #225D5E !important;
}

.clickable-content-cell {
  color: mat.get-color-from-palette(theme.$admin-app-primary);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.mat-tooltip {
  white-space: pre;
  max-width: initial !important;
}

.mat-header-cell.highlight,
.mat-cell.highlight {
  background: lightblue;
  margin: auto 5px;
}

.mat-mdc-dialog-panel.dialog-pre-wrap {
  .mat-mdc-dialog-content {
    white-space: pre-wrap;
  }
}
