@import "@core/scss/global";

.tableContainer {
  mat-header-row {
    top: -20px !important;
  }
}

app-admin-table {
  app-admin-tag-list {
    pointer-events: none;
  }
}

mat-row.archived {
  opacity: 0.25;
}

mat-tab-header {
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 2px 18px 1px rgba(49, 53, 72, .1);
}

.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  font-family: 'Montserrat', sans-serif !important;
}

.mdc-button {
  line-height: normal !important;
}

.inline-list-icon {
  font-size: 16px;
  button {
    font-size: inherit !important;
    mat-icon {
      position: relative;
      right: 10px;
      top: 4px;
    }
  }

}

.multiline-tooltip {
  white-space: pre-line !important;
}

.small-checkbox-field {
  mat-form-field {
    display: none;
  }
}

app-admin-form-checkbox.centered {
  position: relative;
  top: 8px;
}

// add scrollable app-subscription-writer menu and writer
.page-content:has(mat-sidenav-container app-subscription-writer) {
  overflow: auto;

  app-subscription-writer {
    max-height: 100%;

    .carded {
      max-height: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;

      .writer-content {
        overflow: auto;

        .menu {
          overflow: auto;
        }

        .editor {
          overflow: auto;
        }
      }
    }
  }
}
