@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);

  admin-navigation {
    mat-nav-list {
      .mat-mdc-subheader {
        color: mat.get-color-from-palette($primary) !important;
      }
    }

    a.mat-mdc-list-item {
      &.active {
        &::after {
          background-color: mat.get-color-from-palette($primary) !important;
        }
      }
    }

  }
}
