@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);

  $primary-color: mat.get-color-from-palette($primary);
  $accent-color: mat.get-color-from-palette($accent);
  $warn-color: mat.get-color-from-palette($warn);

  $primary-contrast-color: mat.get-contrast-color-from-palette($primary, 500);
  $accent-contrast-color: mat.get-contrast-color-from-palette($accent, 500);
  $warn-contrast-color: mat.get-contrast-color-from-palette($warn, 500);

  --primary-color: #{$primary-color};
  --accent-color: #{$accent-color};
  --warn-color: #{$warn-color};

  --primary-contrast-color: #{$primary-contrast-color};
  --accent-contrast-color: #{$accent-contrast-color};
  --warn-contrast-color: #{$warn-contrast-color};

  .primary-color {
    color: $primary-color !important;
  }
  .accent-color {
    color: $accent-color !important;
  }
  .warn-color {
    color: $warn-color !important;
  }

  .primary-color-bg {
    background-color: $primary-color !important;
  }
  .accent-color-bg {
    background-color: $accent-color !important;
  }
  .warn-color-bg {
    background-color: $warn-color !important;
  }

  $table-status-colors: (
    empty: #9e9e9e,
    partial: #fbc02d,
    completed: #198754,
    not-answering: #dc3545,
    bo-validated: #0d6efd,
    client-integration-needed: #ffc107
  );

  // Generate CSS custom properties for status colors
  @each $name, $color in $table-status-colors {
    --table-status-#{$name}: #{$color};

    // Generate utility classes
    .table-status-#{$name}-color {
      color: $color !important;
    }
    .table-status-#{$name}-bg {
      background-color: $color !important;
    }
  }
}
